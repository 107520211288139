import { container, title } from "assets/jss/material-kit-react.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const profilePageStyle = {
  container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  main: {
    background: "linear-gradient(155deg, #ffffff 0%, #ffffff 50%, #e9ecef 80%)",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
      "@media (max-width: 900px)": {
        margin: "-120px 15px 0px",
      },
      "@media (max-width: 600px)": {
        margin: "-150px 14px 0px",
      }
    },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "center",
    paddingBottom: "3.5rem",
    marginTop: "-4rem",
    "@media (max-width: 800px)": {
      color: "#FFFFFF",
      textAlign: "center",
      paddingBottom: "3.5rem",
      marginTop: "-18rem",
    }
  },
  intro: {
    wordSpacing: "0.2rem",
    fontSize: "1.5rem",
    fontWeight: "200",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: "2rem",
    marginBottom: "0.25rem",
    "@media (max-width: 800px)": {
      wordSpacing: "0.2rem",
      fontSize: "1rem",
      fontWeight: "200",
      textAlign: "center",
      textTransform: "uppercase",
      marginTop: "2rem",
      marginBottom: "0.25rem",
    }
  },
  title: {
    marginTop: "-2px",
    fontSize: "4.25rem",
    fontWeight: "900",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    WebkitTextStroke: "1px white",
    color: "rgba(0, 0, 0 , 0.0)",
    "@media (max-width: 800px)": {
      marginTop: "-2px",
      fontSize: "3rem",
      fontWeight: "900",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      WebkitTextStroke: "1px white",
      color: "rgba(0, 0, 0 , 0.0)",
    }
  },
  iconarea: {
    marginTop: "-34rem",
    height: "auto",
    position: "relative",
    backdropFilter: "blur(2px)",
  },
  border: {
  },
  specstext: {
    color: "#000103",
    fontSize: "15px",
    fontWeight: "500",
    textAlign: "center",
    padding: "5rem 8rem",
    opacity: "0.7",
    paddingBottom: "0rem",
    textTransform: "uppercase",
    "@media (max-width: 800px)": {
      color: "#000103",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "center",
      padding: "3rem 1rem",
      opacity: "0.7",
      paddingBottom: "0rem",
    },
    "@media (max-width: 600px)": {
      color: "#000103",
      fontSize: "11px",
      fontWeight: "500",
      textAlign: "center",
      padding: "3rem 1rem",
      opacity: "0.7",
      paddingBottom: "0rem",
    },

  },
  homeimage: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  imagewrap: {
    width: "100%",
    height: "900px",
    "@media (max-width: 800px)": {
      width: "100%",
      height: "400px",
    }
  },
  headinginverse: {
    marginTop: "-2px",
    fontSize: "3.4rem",
    fontWeight: "800",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#0b292c",
    marginBottom: "4rem",
    marginLeft: "10%",
    "@media (max-width: 800px)": {
      marginTop: "-2px",
      fontSize: "2.4rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "2%",
    },
    "@media (max-width: 600px)": {
      marginTop: "-2px",
      fontSize: "2.4rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  greentext: {
    color: "#60b360",
  },
  preheadinginverse: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    color: "#0b292c",
    opacity: "0.9",
    marginBottom: "5px",
    marginLeft: "10.5%",
    "@media (max-width: 1000px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginBottom: "5px",
      marginLeft: "10.5%",
      paddingTop: "7rem",
    },
    "@media (max-width: 800px)": {
      fontSize: "1rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginBottom: "5px",
      marginLeft: "2.5%",
      paddingTop: "7rem",
    },
    "@media (max-width: 600px)": {
      fontSize: "1rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginBottom: "5px",
      paddingTop: "5rem",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  parawrapinverse: {
    position: "relative",
    marginLeft: "25%",
    marginRight: "40px",
    "@media (max-width: 600px)": {
      marginLeft: "25px",
      marginRight: "25px",
    }
  },
  paratextinverse: {
    fontSize: "16px",
    marginBottom: "1.2rem",
    marginRight: "6rem",
    marginLeft: "10%",
    "@media (max-width: 800px)": {
      fontSize: "14px",
      marginBottom: "1.2rem",
      marginRight: "2%",
      marginLeft: "2%",
    },
    "@media (max-width: 600px)": {
      fontSize: "13px",
      marginBottom: "1.2rem",
      marginRight: "2%",
      marginLeft: "2%",
      textAlign: "center",
    }
  },
  paratextinversetwo: {
    fontSize: "16px",
    marginBottom: "1.2rem",
    marginRight: "6rem",
    marginLeft: "10%",
    fontWeight: "400",
    "@media (max-width: 800px)": {
      fontSize: "14px",
      marginBottom: "1.2rem",
      marginRight: "2%",
      marginLeft: "2%",
      fontWeight: "400",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
      paddingTop: "1rem",
      marginBottom: "1.2rem",
      marginRight: "2%",
      marginLeft: "6%",
      fontWeight: "400",
    }
  },
  ulist: {
    marginLeft: "10%",
    opacity: "0.8",
        "@media (max-width: 800px)": {
      fontSize: "11px",
      marginLeft: "10%",
      opacity: "0.8",
    },
    "@media (max-width: 600px)": {
      fontSize: "11px",
      marginLeft: "10%",
      opacity: "0.8",
    }
  },
  disclaimer: {
    textAlign: "right",
    marginRight: "10%",
    fontWeight: "300",
    fontSize: "10px",
    opacity: "0.9",
    "@media (max-width: 800px)": {
      textAlign: "right",
      marginRight: "10%",
      fontWeight: "300",
      fontSize: "8px",
      opacity: "0.9",
    }
  },
  paddingone: {
    paddingBottom: "10rem",
    "@media (max-width: 800px)": {
      paddingBottom: "4rem",
    }
  },
  paddinghalf: {
    paddingBottom: "2rem",
  },
  mainwrap: {
    margin: "auto",
    maxWidth: "2100px",
  }, 
};

export default profilePageStyle;
