import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";

import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";

// Sections for this page


const dashboardRoutes = [];

class ProfilePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/renderspecs.png")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
              <div className={classes.brand}>
                <div className={classes.intro}>
                  flexible size for
                  </div>
                  <h1 className={classes.title}>Flexible Businesses</h1>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>

          <div className={classes.specstext}>
          COLLIERY BUSINESS PARK – OFFERING FLEXIBILITY AND EFFICENCY TO BUSINESS IN SOUTH WALES

          </div>
          </div>

          <div className={classes.paddinghalf}></div>
          <SectionPills />

          <div className={classes.container}>

          <div className={classes.preheadinginverse}>
          SUSTAINABILITY
                  </div>
                 
                <h1 className={classes.headinginverse}>Our <span className={classes.greentext}>GWYRDD</span> initiative</h1>

                <p className={classes.paratextinverse}>
                We are committed to reduce carbon emissions both during construction, and the ongoing occupation of all our developments. We also strive to ensure to better the 2010 building regulations CO2 emissions rates by a minimum of 9%. In line with our tree planting commitment, where possible with planning we seek to create planted areas to increase tree numbers on our developments.

                </p>

                <p className={classes.paratextinversetwo}>
                Our GWYRDD initiative includes:
                </p>
    
                <ul className={classes.ulist}>
                  <li>Breeam Excellent Certification</li>
                  <li>A development meeting the Wales Government Sustainable Building guidelines</li>
                  <li>Aggressive U value targets</li>
                  <li>EV Charging points</li>
                  <li>PV roof 15% roof lights*</li>
                  <li>Cycle Park</li>
                  <li>Rain water harvesting and recycling</li>
                  <li>Heat Pump storage*</li>
                </ul>
              <br/>
                  <p className={classes.disclaimer}>* subject to planning.</p>
                </div>
                
                <SectionCarousel/>
              

          <div className={classes.paddinghalf}></div>
          
         

          
          </div>
          
        
        <Footer />
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(ProfilePage);